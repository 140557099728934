import React from 'react';

const Email = () => {
  return (
    <svg
      width="23"
      height="18"
      viewBox="0 0 23 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.7715 0.697266H1.09832C0.903397 0.697494 0.716526 0.775049 0.57872 0.912908C0.440915 1.05077 0.363433 1.23767 0.363281 1.43259V2.91188C0.363281 2.98003 0.437188 3.05825 0.497579 3.08989L11.3639 9.2966C11.3917 9.31251 11.4233 9.32075 11.4553 9.32047C11.4881 9.32056 11.5203 9.31182 11.5485 9.29517L22.0864 3.09592C22.1456 3.06285 22.3026 2.97543 22.3616 2.93517C22.4329 2.88657 22.5065 2.84257 22.5065 2.75544V1.43231C22.5063 1.23743 22.4288 1.0506 22.291 0.912807C22.1532 0.775009 21.9664 0.697494 21.7715 0.697266Z"
        fill="#000428"
      />
      <path
        d="M22.4157 5.14227C22.3874 5.12613 22.3553 5.11774 22.3228 5.11795C22.2902 5.11815 22.2583 5.12693 22.2302 5.14342L16.2667 8.65212C16.243 8.66596 16.2226 8.68498 16.2072 8.70776C16.1918 8.73054 16.1817 8.7565 16.1777 8.78371C16.1737 8.81092 16.1759 8.83868 16.1841 8.86494C16.1923 8.8912 16.2063 8.91527 16.225 8.93538L22.1893 15.3647C22.2064 15.3832 22.2272 15.398 22.2503 15.4081C22.2734 15.4182 22.2984 15.4234 22.3236 15.4233C22.3723 15.4233 22.419 15.4039 22.4535 15.3694C22.4879 15.335 22.5073 15.2883 22.5074 15.2396V5.30187C22.5075 5.26954 22.4991 5.23776 22.483 5.20973C22.4669 5.1817 22.4437 5.15843 22.4157 5.14227Z"
        fill="#000428"
      />
      <path
        d="M14.5471 9.83026C14.5188 9.79943 14.4808 9.77924 14.4394 9.77307C14.398 9.76689 14.3557 9.7751 14.3197 9.79633L11.9293 11.2029C11.7893 11.2838 11.6306 11.3269 11.4689 11.3279C11.3071 11.329 11.1479 11.288 11.0068 11.2089L8.9032 10.0071C8.86924 9.98778 8.83 9.97975 8.79117 9.98419C8.75234 9.98864 8.71594 10.0053 8.68723 10.0319L0.698985 17.4415C0.678043 17.4611 0.661964 17.4853 0.652029 17.5122C0.642095 17.5391 0.63858 17.5679 0.641764 17.5964C0.644948 17.6249 0.654744 17.6522 0.67037 17.6763C0.685996 17.7003 0.707021 17.7203 0.731768 17.7348C0.856 17.8079 0.975919 17.8427 1.09785 17.8427H21.5596C21.5954 17.8427 21.6305 17.8323 21.6604 17.8126C21.6903 17.7929 21.7138 17.7649 21.7279 17.7319C21.7422 17.6992 21.7467 17.663 21.7406 17.6278C21.7346 17.5926 21.7184 17.5599 21.6939 17.5338L14.5471 9.83026Z"
        fill="#000428"
      />
      <path
        d="M6.92976 9.15539C6.95092 9.13583 6.96718 9.11157 6.97723 9.08456C6.98728 9.05756 6.99084 9.02857 6.98762 8.99993C6.98441 8.9713 6.9745 8.94382 6.9587 8.91972C6.9429 8.89562 6.92166 8.87558 6.89669 8.8612L0.637343 5.28608C0.609408 5.27022 0.5778 5.26197 0.545677 5.26216C0.513555 5.26235 0.482045 5.27096 0.4543 5.28715C0.426554 5.30334 0.403544 5.32653 0.387572 5.3544C0.371599 5.38227 0.363224 5.41384 0.363284 5.44597V14.8266C0.363082 14.8625 0.373389 14.8976 0.392931 14.9276C0.412473 14.9576 0.440392 14.9813 0.473231 14.9956C0.506071 15.0099 0.542389 15.0143 0.577692 15.0082C0.612995 15.0021 0.645732 14.9858 0.671852 14.9612L6.92976 9.15539Z"
        fill="#000428"
      />
    </svg>
  );
};

export default Email;
