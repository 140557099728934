import React from 'react';

const Linkedin = () => {
  return (
    <svg
      width="23"
      height="21"
      viewBox="0 0 23 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.50003 21V6.83134H0.784097V21H5.50003ZM3.14267 4.89568C4.7872 4.89568 5.81084 3.8077 5.81084 2.44807C5.78021 1.05779 4.78727 0 3.17388 0C1.56075 0 0.505859 1.05781 0.505859 2.44807C0.505859 3.80776 1.52925 4.89568 3.11189 4.89568H3.14253H3.14267ZM8.11029 21H12.8262V13.0875C12.8262 12.6641 12.8569 12.241 12.9814 11.9383C13.3223 11.0923 14.0983 10.216 15.401 10.216C17.1075 10.216 17.7902 11.5153 17.7902 13.42V20.9999H22.5059V12.8757C22.5059 8.52366 20.1793 6.4987 17.0765 6.4987C14.5324 6.4987 13.4154 7.91876 12.7948 8.88595H12.8263V6.83104H8.11039C8.17228 8.16055 8.11039 20.9997 8.11039 20.9997L8.11029 21Z"
        fill="#000428"
      />
    </svg>
  );
};

export default Linkedin;
